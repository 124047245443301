<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-container>
          <v-row class="mt-5">
            <v-col cols="8">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
                <v-card-title class="subtitle-2">PRELIMINARES - NUDEZ NO VERSO</v-card-title>
                <span class="mb-2"><a href="/pix-preliminares" class="linkComprar">Adquira o seu aqui!</a></span>
                <v-card-text
                  class="black--text"
                  v-html="DadosTexto.Texto"
                ></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <div id="galeria" class="mt-5">
                <vue-picture-swipe :items="items"></vue-picture-swipe>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container" id="topopreliminares" style="margin-bottom: 20vh;">
          <span class="mb-2"><a href="#galeria" class="linkGaleria">Galeria</a></span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-card-title class="subtitle-2">PRELIMINARES - NUDEZ NO VERSO</v-card-title>
            <span class="mb-2"><a href="/pix-preliminares" class="linkComprar">Adquira o seu aqui!</a></span>
            <v-card-text
              class="black--text"
              v-html="DadosTexto.Texto"
            ></v-card-text>
          </v-card>
          <div id="galeria" class="mt-5">
            <vue-picture-swipe :items="items"></vue-picture-swipe>
          </div>
          <span><a href="#topopreliminares" class="linkGaleria">Topo</a></span>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'LivroPreliminares',
  components: {
    AppBar
  },
  data () {
    return {
      DadosTexto: {
        Titulo: "PRELIMINARES - NUDEZ NO VERSO",
        Texto: `A obra, lançamento da Bienal Internacional do Livro do Rio de Janeiro (2021), é o primeiro livro de poemas autorais publicado pela escritora. <br /><br />
                Com elementos que trazem o cotidiano das relações humanas que envolvem desejo e erotismo, a escrita evolui página a página, poema a poema, de um modo envolvente como no jogo real das preliminares.<br /><br />
                "Esse livro nasce do desejo... em muitos – ou todos os – sentidos.
                Um dos vetores principais que me moveu à escrita de poemas eróticos foi o desejo de empreender todos os recursos linguísticos de que eu fosse capaz na tentativa de descrever aquilo que provoca, o não explícito, o sensual.
                E, confesso, por diversas vezes tive a certeza de que essa iniciativa já nascia fadada à frustração, uma vez que nada do que se possa dizer por escrito alcança a riqueza de sensações que o corpo entregue ao desejo experimenta.
                (…)<br /><br />

                Entretanto, esse trabalho (Preliminares) é próprio e, nesse sentido, tanto inédito quanto inovador, porque as imagens e percepções que eu empresto à transcrição do erotismo são as minhas, à minha maneira, à quentura da minha erupção.” - trecho do Posfácio de “Preliminares - nudez no verso” pela própria autora.<br /><br />

                O livro surgiu de um concurso literário ocorrido durante a pandemia e enfrentou os desafios desse tempo de reclusão até que alcançou ser lançado.<br /><br />
                “Sou uma voz feminina que reforça a derrocada dos tabus pelo simples fato de dizer livremente." (Daniela Laubé)<br /><br /><br /><br />

                <strong>O que dizem os leitores de “Preliminares – nudez no verso”</strong><br /><br />

                <i>“Em Preliminares, Daniela adentra o universo da poesia erótica com um tom artístico do sublime, explorado através da cuidadosa escolha das palavras, referências literárias e sonoridades (...) Quem pensa que lerá um livro ‘hot’ como qualquer outro se engana, e muito.”</i>
                <br /><center>Carollina Costa, RJ, professora e linguista</center><br /><br />

                <i>“Este livro despe o erotismo enquanto veste de uma poesia nua o discurso e o poder de uma mulher que não sucumbe e tem coragem de expor seus desejos em uma sociedade enraizada no patriarcado.”</i>
                <br /><center>Xúnior Matraga, MG, professor e poeta</center><br /><br />

                <i>“(...) Daniela Laubé explora o erotismo de maneira delicada, inteligente e metalinguística. Ela brinca com as palavras, numa espécie de jogo sensual, bastante interessante e sofisticado. (...) Em resumo, ‘Preliminares’ é um convite ao livre desejo, misturando a paixão pela arte ao fervor dos amantes insaciáveis, cuja linguagem nos faz brincar com as palavras assim como os corpos fazem instintivamente.”</i>
                <br /><center>Nicole Ayres, RJ, escritora</center><br /><br />

                <i>“Atualmente, parece haver uma pressa de se desnudar a prova carnal, bem maior do que saborear o entorno e sorver pouco a pouco o sentido do sentimento. Dani, ao contrário disso, traz em sua obra o deleite.”</i>
                <br /><center>Igor Calazans, RJ, poeta e jornalista</center><br /><br />

                <i>“Em nenhum momento o livro é vulgar. É um erótico sofisticado, na medida em que fala do erotismo trazendo referências externas – como Salomão e Cântico dos Cânticos...”</i>
                <br /><center>Luiz Corain, SP, advogado</center><br /><br />
        `
      },
      items: [{
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154660130689034/preliminares_1.JPG',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154696570798170/preliminares_1.jpg',
          w: 180,
          h: 320
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154659015004160/preliminares_4.JPG?width=377&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154695216042115/preliminares_4.jpg',
          w: 377,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154661812605021/preliminares_5.JPG?width=377&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154695438352414/preliminares_5.jpg',
          w: 377,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154659434430514/preliminares_3.JPEG',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154697044770846/preliminares_3.jpeg',
          w: 377,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154661447712818/preliminares_6.JPG?width=377&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154695648051240/preliminares_6.jpg',
          w: 377,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154660755644497/preliminares_7.JPG?width=377&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154695895519282/preliminares_7.jpg',
          w: 377,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154660457857124/preliminares_8.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154696214302730/preliminares_8.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154186832855080/999154659769991168/preliminares_2.JPEG',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154186832855080/999154696814075904/preliminares_2.jpeg',
          w: 377,
          h: 670
        },
      ]
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
    .linkComprar {
      display: flex;
      flex-direction: row;
      text-decoration: underline;
      justify-content: center;
      color:#70445E;
    }
</style>
